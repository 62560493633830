import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import api from '../../../services/API';

export default function Regulamento() {
  const [cenario, setCenario] = useState(null);
  const parceiro = useSelector((state) => state.parceiro);

  const dadosRevenda = async () => {
    const resposta = await api
      .get(`/api/revendas/cnpj/${parceiro.cnpjRevenda}`)
      .catch((err) => console.log('Erro na consulta da revenda', err.message));

    if (resposta) {
      setCenario(resposta.data.cenario);
    }
  };

  useEffect(() => {
    dadosRevenda();
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>REGULAMENTO American Points E SMART</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="text-justify">
        <div class="mt-5 mb-5">
          <p>
            O “American Points” é um Programa de Incentivo para vendedores cadastrados, no qual cada produto da marca Clube Gaya vendido, tem um valor de pontos específico, que poderá ser alterado a qualquer momento.
          </p>
          <p>
            O vendedor da Loja cadastrado acumulará pontos e os trocará por
            prêmios, de acordo com regras de participação e pontuação descritas
            neste documento.
          </p>
          <p>
            O presente Programa “American Points” é desenvolvido e realizado pela Smart Tecnologia em Vendas S/A, inscrita no CNPJ sob nº 20.893.968/0001-00, denominada “Smart”, e promovido pela SWEET ADMINISTRAÇAO E REPRESENTAÇAO LTDA, inscrita no CNPJ sob o nº 44.432.772/0001-78, denominada pela Clube Gaya.
          </p>
          <p>
            Todas as regras de pontuação, resgates e validade, poderão ser
            atualizadas sem prévio aviso, sendo incluídas neste documento e
            comunicado aos participantes de forma clara. A pré-seleção das lojas
            participantes é de responsabilidade do Clube Gaya.
          </p>
          <p>
            O participante, ao se cadastrar no Programa, aceita e concorda com
            as regras abaixo.
          </p>
          <ol>
            <li>REGULAMENTO</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O presente Programa “American Points” será
              realizado conforme regras descritas neste Regulamento.
              <br />
              2. Este Programa é direcionado exclusivamente aos membros do
              programa Clube Gaya cadastrados nos estabelecimentos credenciados,
              sendo todos pessoas físicas e maiores de 18 (dezoito) anos, que já
              tenham o hábito de se relacionar com a empresa promotora.
              <br />
              3. A Clube Gaya é destinada exclusivamente aos vendedores das Lojas
              participantes, não sendo permitida a participação de
              proprietários.
              <br />
              4. Os dados fornecidos pelas Lojas ou participantes que não forem
              reais, automaticamente serão descadastrados e os pontos expirados,
              podendo estender para exclusão da loja participante e expiração
              dos pontos de todos os participantes da Loja.
              <br />
              <p style={{ marginLeft: '2rem' }}>
                1. Através da Equipe de apoio ao membro do programa, no
                Formulário de Cadastro, onde a senha inicial será 123456,
                devendo ser alterada através do menu MINHA CONTA.
              </p>
              5. Nunca forneça a senha de acesso ao site clubegaya.com.br a terceiros, a senha é de total responsabilidade do vendedor da Loja cadastrado. Acesse a área Minha Conta no painel ou pelo link: <a target="_blank" href="clubegaya.com.br/perfil">clubegaya.com.br/perfil</a> e altere sua senha inicial. Em caso de dúvidas ou extravios, entre em contato com a Equipe Clube Gaya pelo telefone (11) 91518-0868 ou pelo <a href="malito:contato@clubegaya.com.br">contato@clubegaya.com.br</a>
              <br />
              6. A finalidade deste Programa é gerar relacionamento e
              engajamento dos Vendedores Lojas com os produtos da Clube Gaya. Os
              Vendedores das Lojas cadastrados serão pontuados através dos
              produtos vendidos dentro do Estabelecimento cadastrado, de acordo
              com as quantidades adquiridas da Clube Gaya.
              <br />
              7. Os produtos válidos para pontuação são os produtos
              selecionados, vendidos pela Clube Gaya.
              <br />
              8. Os parceiros cadastrados serão denominados e identificados como
              Membros American Points, sendo recompensados,
              eventualmente, desde que sejam cumpridas as condições descritas no
              presente regulamento.
              <br />
              9. A Smart executará o American Points diretamente
              aos Vendedores de Lojas comerciais indicados e cadastrados para o
              Programa de Relacionamento pela Clube Gaya.
              <br />
              10. Caso a Loja do vendedor cadastrado estiver com inadimplência
              com a Clube Gaya, os prêmios podem não ser entregues e o Ponto de
              Venda pode ser excluído do Programa.
              <br />
            </p>
            <li>REGRAS DE PONTUAÇÃO</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O membro do programa American Points, deverá
              enviar o código do produto vendido e valor de venda pelo site{' '}
              <a target="_blank" href="clubegaya.com.br/perfil">
                clubegaya.com.br/perfil
              </a>{' '}
              para que a pontuação seja realizada e confirmada.
              <br />
              2. Em caso de pontuação automática, os pontos serão computados de
              acordo com a periodicidade previamente acordada com a sua loja,
              podendo ser diária, semanal, quinzenal ou mensal. Os pontos
              obtidos pelos participantes, tem validade de 365 (trezentos e
              sessenta e cinco) dias, desde que a conta permaneça ativa.
              <br /> 3. Da mesma forma, caso o participante não atinja
              quantidade suficiente de pontos para troca de prêmios no site{' '}
              <a target="_blank" href="clubegaya.com.br/perfil">
                clubegaya.com.br/perfil
              </a>{' '}
              , os pontos serão expirados, decorridos os prazos acima indicados.
              <br /> 4. O extrato de pontos poderá ser consultado no
              site: clubegaya.com.br/perfil, no menu PONTUAÇÕES,
              juntamente com login (login: número do CPF) e senha.
              <br /> 5. As pontuações são validadas diretamente com o volume de
              compras realizadas pelo estabelecimento com a Clube Gaya.
              <br /> 6. O produto que não tiver saldo no momento do envio,
              ficará com o status "Em Análise" até que haja saldo disponível
              para liberação da pontuação do produto.
              <br /> 7. Os pontos são pessoais e intransferíveis.
              <br /> 8. A Smart se reserva no direito da revisão dos pontos até
              90 (noventa) dias após a data de pontuação, para conferência e
              correção se necessário.
              <br /> 9. Os pontos obtidos no decorrer deste Programa poderão ser
              utilizados de uma única vez ou de forma fracionada.
              <br /> 10. Na eventualidade de algum membro participante ser
              desligado do estabelecimento cadastrado, o saldo de pontos,
              permanecerão ativos, respeitando as regras de validade da
              pontuação.
            </p>
            <li>REGRAS DE RESGATES DE PRÊMIOS</li>
            <p style={{ marginLeft: '2rem' }}>
              1. Está disponível no site do programa:{' '}
              <a target="_blank" href="www.clubegaya.com.br">
                www.clubegaya.com.br
              </a>{' '}
              o CATÁLOGO DE PRÊMIOS contendo os produtos, que poderão ser
              obtidos pelos parceiros a partir da utilização do seu crédito de
              PONTOS vigentes, nos termos deste REGULAMENTO e de outras regras
              disponibilizadas no site.
              <br /> 2. O American Points poderá alterar,
              substituir ou cancelar os prêmios disponíveis.
              <br /> 3. Os resgates de prêmios deverão ser realizados através do
              site:{' '}
              <a target="_blank" href="www.clubegaya.com.br">
                www.clubegaya.com.br
              </a>{' '}
              , mediante a login e senha e saldo suficiente para o item
              escolhido.
              <br />
              4. Em caso de falha sistêmica ou em casos que o produto seja
              resgatado fora da regra de pontuação vigente, os resgates serão
              automaticamente cancelados.
              <br /> 5. Após o resgate solicitado no site descrito acima, o
              membro do programa American Points receberá um e-mail
              e a equipe de apoio ao vendedor Loja entrará em contato através do
              telefone de cadastro se necessário.
              <br /> 6. O prêmio resgatado será entregue para o vendedor Loja no
              estabelecimento cadastrado ou no endereço cadastrado do membro do
              Programa em até 20 dias úteis, sendo a entrega feita mediante
              Formulário de Resgate, válido como recibo assinado, ou confirmação
              da transportadora.
            </p>
            <li>POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</li>
            <p style={{ marginLeft: '2rem' }}>
              1. Os dados pessoais serão coletados pela Smart com a finalidade
              de administração e operação do PROGRAMA, incluindo o uso para os
              fins de: (i) cadastro junto ao PROGRAMA e contato com o Membro do
              programa American Points; (ii) acúmulo de PONTOS;
              (iii) entrega de premiações; (iv) otimização do catálogo de
              prêmios; (v) desenvolvimento de inteligência de mercado,
              parâmetros e perfil de consumo, bem como outras atividades
              associadas ao bom funcionamento e desenvolvimento do PROGRAMA.
              <br />
              2. Os dados serão armazenados em bancos de dados exclusivos do
              Programa, em ambiente seguro.
              <br /> 3. A SMART não compartilhará os dados pessoais aqui
              mencionados, exceto com a RUBY ROSE.
              <br /> 4. A RUBY ROSE garante que os dados recebidos serão
              utilizados para análise de mercado e encaminhamento de publicidade
              sobre produtos da marca RUBY ROSE.
              <br /> 5. A SMART garante que os dados recebidos serão armazenados
              em ambiente seguro e com acesso restrito somente a empregados da
              empresa, todos contratualmente obrigados a mantê-los em proteção e
              sob sigilo.
              <br /> 6. A SMART garante também que os dados serão compartilhados
              com empresas provedoras do serviço de armazenamento e gestão dos
              dados, todas contratualmente obrigadas a mantê-los em segurança e
              sob sigilo.
              <br />
              7. Quando muito necessário, a Clube Gaya poderá compartilhar os
              dados recebidos com prestadores de serviço, para aprimorar o
              atendimento da empresa aos seus clientes, bem como com consultores
              jurídicos, para ingresso ou defesa em ações administrativas ou
              judiciais. Todos contratualmente obrigados a manter a
              confidencialidade das informações. Poderá compartilhar também para
              o cumprimento de determinação de autoridade judicial ou
              administrativa competente. O Membro do programa
              American Points poderá a qualquer momento corrigir dados incompletos,
              inexatos ou desatualizados informados em cadastro, por meio do
              menu “MINHA CONTA” após o Login.
              <br /> 8. A qualquer momento, o membro do programa Clube de
              Vantagens Clube Gaya pode solicitar a exclusão total de seus dados,
              ciente de que com a exclusão ele não conseguirá mais acessar as
              informações do American Points.
            </p>
            <li>CONSIDERAÇÕES GERAIS</li>
            <p style={{ marginLeft: '2rem' }}>
              1. No primeiro acesso, o Membro do programa Clube Gaya deve efetivar o seu ACEITE a este REGULAMENTO, formalizando, portanto, a sua decisão de participar do PROGRAMA, declarando que foi esclarecido sobre o programa, os dados pessoais coletados, a finalidade e a possibilidade de exclusão dos dados a qualquer momento a seu pedido e manifestando ter amplo conhecimento e concordar integralmente, de maneira plena e irrestrita, com todos os termos e condições da Clube Gaya, estando apto a usufruir dos benefícios do PROGRAMA que lhe forem aplicáveis. Caso o ACEITE não seja efetivado, o acesso do Clube Gaya estará inapto para uso.
              <br />
              2. A participação neste Programa implica na aceitação total de todos os itens deste Regulamento, que poderá ser alterado pela Smart tantas vezes quanto necessário, a seu exclusivo critério.
              <br />
              3. No decorrer deste Programa, a Smart irá se comunicar com o público-alvo, de forma próxima e direta, utilizando-se dos principais canais de comunicação.
              <br />
              4. O Programa, por exclusivo critério da Smart, poderá ser interrompida ou encerrada a qualquer tempo.
              <br />
              5. Serão automaticamente desclassificados os participantes que descumprirem os itens do presente regulamento, agirem de má-fé ou que, de alguma forma, burlarem as regras e condições deste Regulamento ou utilizarem mecanismos fraudulentos ou ilícitos.
              <br />
              6. Ao participar deste Programa, o vendedor da Loja concorda, desde já, com a utilização de seu nome e imagem, para divulgação desta ação, em todos os meios de comunicação, pelo prazo máximo de 3 (três) anos contado a partir da data de cadastro, sem que isso traga qualquer tipo de ônus para a empresa a Smart ou Promotora.
              <br />
              7. A participação no Clube Gaya é voluntária, facultativa e gratuita, não havendo qualquer sanção àqueles que optem por não participar; e as dúvidas relativas a este Programa poderão ser solucionadas de segunda-feira a sexta-feira (exceto feriados) das 9hs às 18shs, através dos canais de comunicação: Canal Fale Conosco no site Clube Gaya (após Login): WhatsApp: (11) 91518-0868 E-mail: <a href="malito:contato@clubegaya.com.br">contato@clubegaya.com.br</a>
              <br />
            </p>
            <li>UTILIZAÇÃO DO CARTÃO</li>
            <p style={{ marginLeft: '2rem' }}>
              1. No Primeiro resgate em dinheiro, você receberá seu cartão SMART. Os próximos resgates em dinheiro, serão creditados automaticamente neste mesmo cartão. Cada Vendedor terá apenas 1 (um) cartão, é valido somente 1 por CPF.
              <br />
              2. Utilize sempre seu CARTÃO MASTERCARD® Clube Gaya na função CRÉDITO.
              <br />
              3. Com o cartão em mãos, você pode fazer o desbloqueio em <a href="www.smartpoints.com.br/cartao" target="_blank">www.smartpoints.com.br/cartao</a> ou pelo telefone (11) 91518-0868 de segunda a sexta das 9h às 18h.
              <br />
              4. Para DESBLOQUEIO pelo site, o usuário precisa entrar no sistema com seu CPF e senha.
              <br />
              5. O prazo da operadora para DESBLOQUEIO do seu cartão é de 24h úteis após a solicitação.
              <br />
              6. Em caso de perda ou roubo do cartão, solicite o BLOQUEIO pelo telefone (11) 91518-0868 de segunda a sexta das 9h às 18h.
              <br />
              7. O prazo da operadora para BLOQUEIO do seu cartão é de 24h úteis após a solicitação.
              <br />
              8. Para alterar a senha, o usuário será direcionado para o site da operadora do cartão onde por segurança, será necessário completar os dados pessoais e fazer um cadastro ao painel de controle.
              <br />
              9. Sua senha é pessoal e intransferível. Você pode alterar a senha impressa na carta que acompanha seu cartão a qualquer momento, clique aqui.
              <br />
              10. O Programa Clube Gaya, não cobra taxas ou pontos para as operações feitas dentro do painel do usuário no site da operadora. As taxas são cobradas pela operadora do cartão e constam neste regulamento.
              <br />
            </p>
            <li>SERVIÇOS E TAXAS DA OPERADORA DO CARTÃO </li>
            <p style={{ marginLeft: '2rem' }}>
              1. O portador do cartão pode utilizar os seguintes serviços no
              painel da operadora:
              <br /> TRANSFERÊNCIA BANCÁRIA.
              <br /> RECARGA DE CELULAR.
              <br /> PAGAMENTO DE CONTA.
              <br /> CONSULTA DE SALDO E EXTRADO DO CARTÃO.
              <br /> ALTERAÇÃO DE SENHA.
            </p>
            <li>RECARGA DE CELULAR</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O premiado pode cadastrar o telefone dele ou de outras pessoas
              para uso em suas recargas. Mas somente telefones cadastrados e
              validados por um código SMS poderão ser recarregados.
              <br />
              2. Somente após a confirmação do código SMS e validação do saldo
              do seu cartão é que a recarregar será feita em no telefone
              pré-cadastrado.
            </p>
            <li>PAGAMENTO DE CONTA</li>
            <p style={{ marginLeft: '2rem' }}>
              1. Somente após a confirmação do código SMS e validação do saldo
              do seu cartão é que o pagamento será feito. Um comprovante será
              gerado validando a sua transação.
            </p>
            <li>CONSULTA DE SALDO E EXTRATO DO CARTÃO E ALTERAÇÃO DE SENHA</li>
            <p style={{ marginLeft: '2rem' }}>
              1. No painel online da operadora de cartão, não haverá custo.
              <br /> 2. Para consulta de saldo online ou alteração de senha, o
              premiado deverá estar com o cadastro completo no painel da
              operadora do cartão.
            </p>
            <li>BANCO 24h</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O Premiado pode consultar o saldo nos bancos 24h mediante taxa
              de R$ 2,24.
              <br /> 2. O Premiado pode efetuar saques na rede Banco 24h
              mediante tarifa de R$ 9,00.
            </p>
            <li>TAXA DE INATIVIDADE</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O American Points, deverá enviar uma foto da Nota
              Fiscal / Cupom Fiscal pelo site do  programa{' '}
              <a href="clubegaya.com.br" target="_blank">
                clubegaya.com.br
              </a>{' '}
              para que a pontuação seja realizada e confirmada.
              <br /> 2. Em caso do cartão estiver sem saldo, não será cobrado a
              taxa de inatividade.
              <br /> 3. Em caso de perda do cartão, você poderá receber um novo
              cartão sem custo, mas para transferir o saldo para um novo cartão,
              será cobrado pela operadora do cartão uma taxa de 2% do seu saldo
              atual.
            </p>
            <li>MASTERCARD® SURPREENDA</li>
            <p style={{ marginLeft: '2rem' }}>
              1. O Usuário pode se cadastrar no programa MASTERCARD® SURPREENDA,
              que é um programa de vantagens administrado pela MASTERCARD®.
              <br /> 2. Todo o acesso e controle do programa MASTERCARD®
              SURPREENDA é separado e independente do programa Clube de
              Vantagens Clube Gaya
            </p>
          </ol>
          <br />
        </div>
      </div>
    </Container>
  );
}
